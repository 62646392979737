.btnheng {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;

    font-size: 12px !important;
    line-height: 22px !important;
    text-align: center;
    color: #318D8C !important;
    border-bottom: 5px solid #57B0AF !important;
    text-transform: capitalize !important;
    padding: 10px 22px 10px 22px !important;
    background-color: white !important;
}

.wtppimgmen {
    margin: 3px 0px 0px 0px !important;
}

.hedermani {
    display: flex !important;
    padding: 0px 0px 0px 47px;
}


.taeam {
    margin: 0px 0px 0px 0px !important;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #09110E;
    opacity: 0.5;
    text-align: center;
}

.msgname {
    margin-bottom: 0 !important;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 100%;
    text-transform: capitalize;
    color: #09110E;

}

.msgemail {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-transform: lowercase;
    color: #09110E;
    opacity: 0.4;
    margin-bottom: 0 !important;
}

.namewtpp {
    margin: 0px 0px 0px 9px;
}

.nabarwtpp {
    margin-bottom: 0 !important;
    font-size: 16px !important;
    line-height: 100%;
    text-transform: capitalize;
    color: #09110E !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.msgnbarname {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    line-height: 100%;
    color: #09110E !important;
    opacity: 0.4 !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.uttaran {
    text-align: center;
}

.bordarbott {
    border: 1px solid #EAEAEA !important;
    margin: 10px 0px 0px 0px;
}

.cekboxcalar {
    color: #FD2420 !important;
}

.ptext1 {
    height: 0 !important;
    margin: 36px 0px 0px 0px !important;
    border: 1px solid #EAEAEA;
}

.ptext {
    padding: 24px 0px 0px 0px;
}

.msglien1 {
    background: #5EC3BB;
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    display: flex;
    width: 100%;
}

.msglien12 {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px 0px 5px 0px;

}

.msglien123 {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 20px 0px 20px 0px;

}


.typtext {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    align-items: center;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 10px !important;
/* max-width: 100% !important; */
}

.typtext1 {
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px;
    color: #808191 !important;
    align-items: center;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 15px !important;
}

.megopohot23 {
    margin: 8px 16px 0px 0px !important;
}


.minith {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 600;
    font-size: 10px !important;
    line-height: 20px;
    text-transform: lowercase !important;
    color: #09110E !important;
    opacity: 0.3;
    text-align: end;
}

.maendivcals {
    display: flex;
    justify-content: left;
    padding: 30px;
    border: 1px solid #EAEAEA;

}

.linktext {
    padding: 0px 0px 0px 10px;
}

.imgdunbox {
    background: #E3F4D7;
    border: 1px solid #8BCC5F;
    border-radius: 16px;

    padding: 10px 15px 10px 15px;
}
.imgdunbox img{
    width: 75px;
    border-radius: 10px;
}

.ikondonlod {
    align-items: center;
    margin: 0px 0px 23px 10px !important;
}

.pohotlenth {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    margin-bottom: 0 !important;
    text-transform: capitalize !important;
}

.pohotlenth12 {
    margin-bottom: 0 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: -0.2px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
}

.lastbtnmsg {
    background: #8BCC5F !important;
    border-radius: 16px !important;
    display: inline-flex;
    background: #8BCC5F;
    mix-blend-mode: normal;
    border-radius: 16px;
    padding: 10px 20px;
    height: 60px;
}

.galari {
    color: white !important;
    margin: 0px 10px 0px 0px !important;
}

.ikonenddov {
    color: white !important;
}

.pakit {
    margin: 0px 40px 0px 0px !important;
    padding: 0px 10px 0px 0px;
}

.imgdunbox12 {
    display: flex;
    justify-content: left;
    margin: 27px 0px 0px 0px;
    gap: 15px;
}

.bordarinput {
    border: 1px solid #EAEAEA !important;
}

.uplodpic2 {
    background-color: white !important;
    color: #9DA09F !important;
    box-shadow: none !important;
    min-width: 0px !important;
    padding: 0 !important;

}

.uplodgalari {
    background-color: white !important;
    color: #9DA09F !important;
    box-shadow: none !important;
    padding: 0 !important;
    min-width: 0 !important;
}

.inputhedar {
    border: none !important;
    border-bottom: none !important;
    border: none !important;
    height: 30px;
    width: 100%;
    padding: 0px 0px 0px 0px;
    font-family: 'Gilroy-500';
    padding: 0px 0px 0px 0px;
    font-family: 'Gilroy-500';
    display: inline-flex;
    width: auto;
    min-width: 80%;
    vertical-align: middle;
    margin-top: 10px;
}
.inputhedar:focus{
    border: none;
    outline: none;
}

.inputmsgtext {
    padding: 25px 0px 0px 0px;
}

.megabox {
    display: flex;
    border-top: 1px solid #EAEAEA !important;
    height: 70px;
    margin: 25px 0px 0px 0px;
}

.inputhedar:hover {
    border: none;

}

.sendbtn {
    background: #FF6A6C !important;
    border: none !important;
    color: white !important;
    cursor: pointer;
}

.avtarbtn {
    background: #FF6A6C !important;
    padding: 21px !important;
}

.ikonsendbtn {
    /* width: 19px; */
    font-size: 18px !important;
    margin: 1px 0px 0px 3px;
}

.boxcantenar {
    border: 1px solid !important;
    width: 828px;
}

.notdifaend {
    display: flex;
    justify-content: end;
    margin: 0px 0px 0px 0px !important;
}

.msglien1 {
    display: flex;
    justify-content: center;
}

/* .typtext {
    width: 100%;
} */

.maendivcals {
    /* padding: 37px 56px 0px 0px !important; */

}

.mormalnabar {
    /* margin: 0px 0px 0px 33px !important; */
    /* justify-content: center;
    display: flex; */
}

.msgcekbox {
    display: flex;
    justify-content: end;
}

.botollogo {
    display: flex;
    justify-content: end;
}

.botollogo {
    margin: 7px 11px 0px 0px;
}

/* .maendivcals23 {
    padding: 30px 70px 30px 30px !important;
} */

.notdifaend99 {
    display: flex;
    justify-content: left;
}

.msglien99 {

    background: #F3F3F3;
    border-radius: 20px;
    padding: 10px;
}

/* .bodarbott {
    border-bottom: 2px solid #EAEAEA !important;
    width: 100%;
    padding: 40px 0px 0px 0px;
} */

.maendivcals33 {
    padding: 0px 30px 10px 30px;
}

.nabarwtpp {
    color: #09110E !important;
    font-size: 16px !important;
    line-height: 100%;
    text-transform: capitalize;
    font-weight: 600 !important;
}

.cekboxcalar12 {
    color: #565656 !important;
}

.bodarbott12 {
    border-bottom: 2px solid #EAEAEA !important;
    width: 100%;
}

.btnbotal {
    padding: 27px 0px 0px 60px;
}

.btnbotal12 {
    padding: 27px 0px 0px 17px;
}

.redyorekoding path {
    /* width : 0px !important; */
    font-size: 15px !important;
}

.ciriket {
    padding: 17px 0px 0px 0px;
}
.notdifaend{

}
.messageleft, .messageright{
    margin-bottom: 10px !important;
}
.messageright .msgbox{
    background: #5EC3BB;
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px;
    text-transform: capitalize;
    align-items: center;
    color: #fff;
    margin-bottom: 10px !important;
}
.messageleft  .msgbox {
    color: #808191;
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px;
    text-transform: capitalize;
    align-items: center;
    margin-bottom: 10px !important;
}
.msgbox .typtext1 {
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px;
    color: #808191 !important;
    align-items: center;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 15px !important;
}
.messageleft .msgbox  .typtext1{
    color: #808191;
}
.messageright .msgbox .typtext1{
    color: #fff !important;
}
.messageleft .notdifaend{
    justify-content: start;
}
.messageright .notdifaend{
    justify-content: end;
}
.messageleft{

}
.chatright{
    padding: 0 30px;;
    height: calc(100vh - 200px);
    overflow-y: scroll;
}
.containerhome{
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
    border: none;
    border-top: 2px solid #EAEAEA !important;
}
.containerhome{
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    border: none;
    border-top: 2px solid #EAEAEA !important;
    align-items: center;
    gap: 20px;
    border-bottom: 0 !important;
}
.previewMain{
    padding: 0 40px 10px;
}
.previewMain img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #e6d9d9;
    margin-right: 5px;
    object-fit: contain;
}
 @media only screen and (max-width: 400px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .insatolmsg{
     padding: 0px 0px 0px 10px;   
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
 }
 @media only screen and (max-width: 500px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
 }
 @media only screen and (max-width: 600px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
  
 } @media only screen and (max-width: 700px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
 }
 @media only screen and (max-width: 800px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
 }
 @media only screen and (max-width: 900px ) {
    .imgdunbox{
        padding: 0px;
    }
    .maendivcals{
        padding: 30px !important;
    }
    .uttaran{
        text-align: left;
    }
    .mormalnabar{
        justify-content: left;
    }
    .msgcekbox{
        justify-content: left;
    }
    .btnbotal{
        padding: 27px 0px 0px 0px;
    }
    .btnbotal12{
        padding: 27px 0px 0px 0px;
    }
    .imgdunbox12{
        padding: 10px 0px 0px 0px;
    }
 }

 .support-list .actives{
    background: red !important;
}
