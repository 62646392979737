@font-face {
    font-family: Gilroy-700;
    src: url(../styles/font/Gilroy-Font/Gilroy-Bold.ttf);
}

@font-face {
    font-family: Gilroy-500;
    src: url(../styles/font/Gilroy-Font/Gilroy-Medium.ttf);
}
.containerdiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.msgmain{
    width: 100%;
    position: sticky;
    position: -webkit-sticky;

}
.userlistnot{
    padding: 0px 0px 0px 30px;
}
.msgdiv {
    display: flex;
    justify-content: space-between;
    /* padding: 20px 0px 0px 0px; */
    /* padding: 0px 30px; */
    padding-right: 28px;
    align-items: center;
}
.btn_serchjk{
    background: transparent;
    border: none;
}

.msgtitel {
    font-size: 20px;
    color: #09110E;
    font-family: Gilroy-700;
    margin-left: 20px !important;
    /* margin-bottom: 10px !important; */
    margin: 0px;
}

.searchbtn {
    box-sizing: border-box;
    /* height: 56px; */
    /* border: none !important; */
    /* border-radius: 16px; */
    font-size: 16px;

    background-color: #F9F9F9;
    background-image: url(../../public/image/Search.svg);
    background-position: 18px 50%;
    background-repeat: no-repeat;
    /* padding: 18px 0px 18px 56px; */
    /* background: white; */
   border: 1px solid #E2EEFD;
    border-radius: 16px;
    padding: 10px 40px 10px 40px;
    cursor: pointer;
    /* display: block; */
   margin: 0px 0px 0px 20px;
}
.btn_serchjk{
    background-color: white;
    margin-top: 20px;
}
#hedinglistsupp{
    width: 100px;
}

.menulistyy{
    /* background-color: aqua; */
    background-image: url(../../public/image/Search.svg);
    background-position: 8px 8px;
    background-repeat: no-repeat;
}
#listparegarfin{
    width: 100px;

}
#avtarlistmasspro{
    padding: 0px 15px 0px 0px;
}
.searchbtn:focus {
    padding: 10px 40px 10px 40px;
    border: none !important;
    background-image: none;
    cursor: text;
}

#listitamiddle{
    margin: 0px 20px 0px 0px;
}

.thisleft {
    /* padding: 34px 0px 0px 30px; */
    border-right: 2px solid #EAEAEA;
    width: 100%;
    /* height: 100vh;
    overflow-y: scroll; */
    position: relative;
}

.borderdiv {
    width: 320px;
    height: 1px;
    border: 2px solid black;
}
.chatDate{
    position: absolute;
    top: 10px;
    right: 10px;
}
.chatlistitem{
    position: relative;
}
.MuiTab-labelIcon {
    background-color: red;
}

.MuiTabroot {
    /* margin: 0px 10px 0px 10px !important; */
    font-family: Gilroy-700 !important;
    background-color: #FFFFFF;
    border-radius: 15px;
}
#datetime{
    width: auto ;
    font-family: Gilroy-500;
    font-weight: bold;
    text-align: end;
}
.this_right{
    width: 100%;
}
.supportCont{
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 15px;
    width: 100% !important;
}
.supportTop{
    position: static;
    padding-top: 20px;
    width: 100%;
    background: white;
    z-index: 3;
    border-radius: 22px;
}
.support_list{
    overflow-y: auto;
}
.actives {
    background: #d8f4f2;
    border-bottom: 0.5px solid rgba(245, 255, 254, 0.5254901961);
}