@font-face {
    font-family: Gilroy;
    src: url(../styles/font/Gilroy/Gilroy-Bold.woff);
}

@font-face {
    font-family: Popins;
    src: url(../styles/font/Poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: Gilroy700;
    src: url(../styles/font/Gilroy/Gilroy-Heavy.ttf);
}

@font-face {
    font-family: Poppins-400;
    src: url(../styles/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: Poppins-500;
    src: url(../styles/font/Poppins/Poppins-Medium.ttf);
}

.uplineimg {
    border-radius: 0px 30px;
    width: -webkit-fill-available;
    /* max-width: 1920px; */
    height: 100%;
}

.btmline {
    border-radius: 0px 30px;
    width: -webkit-fill-available;
    height: 90px;
}

.main {
    width: 100%;
    border-radius: 30px;
    background-color: #FCFCFC;
    background-image: url('../../public/image/background-image.png');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-size: 100vw 100vh;
    background-position: center;
    /* background-size: cover;
    background-position: 100vw 100vh; */
    /* padding: 60px; */
}

.container {
    width: 100%;

}

.mainlp {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.logodiv {
    width: 254px;

}


.singin {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    padding: 25px 50px 35px 50px;
    max-width: 450px;
    margin: 22px 0px 0px 100px;

}

.lo9goimparesan {
    margin-bottom: 60px;
}

.lo9goimparesan p {
    font-family: Gilroy;
    font-size: 34px;
    text-align: center;
    color: #231F20;
}

.userinput {
    border-radius: 12px;
    width: 100% !important;
    margin: 20px 0px 0px 0px !important;
    border: 1px solid #C4C4C4;
    font-family: 'Poppins', sans-serif !important;
}

.userinput input {
    background-color: white;
    border-radius: 12px !important;
    font-family: 'Poppins', sans-serif !important;
}

.userinput fieldset {
    border-color: white;
    border-radius: 12px;
    border: 1px solid #C4C4C4;
}


.signintxt {
    font-family: Gilroy;
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    margin: 0;
    /* margin-bottom: 11px; */
}


.frgttxt {
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    font-family: Gilroy;
    width: 148px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0px 0px;
    border: none;
    background: transparent;
    cursor: pointer;

}


.singbtn {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 16px;
    background: #57B0AF;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
    font-family: Gilroy;
    font-weight: bold;
}

.cypasstxt {
    font-size: 29px;
    margin: 0px;
    text-align: center;
    font-family: Gilroy;
    font-weight: bold;
    color: white;
    width: 100%;
}

.cypasssmltxt {
    font-size: 18px;
    color: white;
    font-family: Gilroy;
    font-weight: bold;
    margin: 0px;
    margin-top: 11px;

}

.cypass {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    padding: 30px 58px 40px 59px;
    max-width: 460px;
    margin: 0px 40px 0px 83px;
    /* margin-bottom: 30px; */

}

.passinput {
    border-radius: 12px;
    width: 100%;
    margin: 20px 0px 0px 0px !important;
    /* border: none; */
    font-family: 'Poppins', sans-serif;
    border: 1px solid #C4C4C4;
}

.passinput input {
    background-color: white;
    border-radius: 12px !important;
    border: none;
    font-family: Poppins-400
}

.passinput fieldset {
    border-color: white;
    border-radius: 12px;
}

.passbtn {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 16px;
    background: #57B0AF;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    margin-top: 34px;
    font-family: Gilroy;
    font-weight: bold;

}

.imgloglistkk {
    position: absolute;
    top: 0px;
    height: 90px !important;
    width: 100%;
}

.container {
    position: relative;
    /* top: 100px; */
    /* top: 0;
    bottom: 0; */
    /* bottom: 50%; */
}

.imgloglistkk2 {
    position: fixed;
    bottom: 0px;
    height: 90px !important;
    width: 100%;
}

.number {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    max-width: 505px;
    padding: 120px 58px 130px 58px;
    margin-bottom: 30px;
}

.numberbtn {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 16px;
    background: #57B0AF;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    margin-top: 28px;
    margin-bottom: 25px;
    font-family: Gilroy;
    font-weight: bold;
}

.verifyno {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    padding: 75px 50px;
    max-width: 505px;
    margin-bottom: 30px;
}

.verifyinp input {
    border-radius: 12px;
    background-color: white;

}

.verifyinp {
    border-radius: 12px;
    /* margin: 48px auto; */
    border: none !important;
    font-family: 'Poppins', sans-serif !important;
    justify-content: center;
    margin-top: 48px !important;
}


.verifytxt {
    font-size: 36px;
    margin: 0px;
    text-align: center;
    font-family: Gilroy;
    font-weight: bold;
    color: white;
    width: 100%;
}

.verifysmltxt {
    font-size: 20px;
    color: white;
    font-family: Gilroy;
    font-weight: bold;
    /* margin: 11px; */
    margin-bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 9px;
    padding-bottom: 0px;
    margin: 0px;
}

.averify {
    color: white;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-family: Gilroy;
    font-weight: bold;
    margin: 35px 0px 0px;
    text-decoration: none;
}

.averify:hover {
    color: white;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-family: Gilroy;
    font-weight: bold;
    margin: 35px 0px 0px;
    cursor: pointer;
    text-decoration: none;
}


.verifybtn {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 16px;
    background: #57B0AF;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
    font-family: Gilroy;
    font-weight: bold;
}

/* .Otpboxlistmenu {
    padding: 10px 0px 0px 0px;
} */

.phonediv {
    background: white;
    border-radius: 12px;
    margin-top: 38px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}



.logo {
    width: 100%;
    margin-left: 84px;
    padding: 0px 0px 30px 0px;
    margin-bottom: -60px;
    margin-top: -55px;
}

.pic {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 100px;
    /* margin-bottom: -20px; */
}

.pic2 {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 100px;
    /* margin-bottom: -20px; */
}

.pic3 {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 80px;
    /* margin-bottom: -20px; */
}

.logotxt {
    font-family: Gilroy;
    font-size: 36px;
    margin: 0px;

}

.logo img {
    width: 65px;
}

.pic img {
    width: 75%;
    margin-bottom: -20px;
}

.otpinp {
    text-align: center;
    margin: 2.5px;
    height: 69px;
    font-size: 36px;
    border-radius: 12px;
    width: 69px;
    font-family: Poppins-500;
    border: 1px solid rgba(196, 196, 196, 1);
}

.otpinp:active {
    border: 1px solid rgba(196, 196, 196, 1);

}

.otp {
    display: flex;
    justify-content: space-evenly;
    /* align-items: end; */
    align-items: flex-end;
    height: 116px;
}




.mycountry {
    border-radius: 12px !important;
    padding-left: 15px;
}



.myinput {
    height: 57px !important;
    width: 100% !important;
    padding: 0px;
    border: 1px solid #C4C4C4;
    border-radius: 12px !important;
    font-family: Poppins-400;
    font-size: 14px;
}


.layout {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

}

.mybutton {
    background: white !important;
    border: none !important;

}

.mydrop {
   text-align: justify !important;
}

.App {
    font-family: sans-serif;
    text-align: center;
    background-color: #f3f3f3;
    padding: 20px;
    margin: auto
}

.otpinput {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otpinput input {
    text-align: center !important;
    margin: 0px 10px !important;
    height: 69px !important;
    font-size: 36px !important;
    box-shadow: none !important;
    border-radius: 12px !important;
    width: 69px !important;
    font-family: Poppins-500 !important;
    border: 1px solid #C4C4C4 !important;
    padding: 0px !important;
}

.otperr {
    color: red;
    font-family: Poppins-500 !important;
    font-size: 15px;
}

.Booleanlistmego {
    padding: 0px !important;
}
.Booleanlistmego2 {
    padding: 0px !important;
    margin-left: 20px;
}
.singin2 {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    padding: 50px 45px 60px 45px;
    max-width: 505px;
    /* margin: 10px 0px 0px 0px; */
    /* margin-bottom: 30px; */
}

.list_padinglog {
    padding: 0px !important;
}

.singin3 {
    display: flex;
    justify-content: center;
    background: radial-gradient(63.51% 63.51% at 49.9% 45.53%, #FFD6D8 0%, #F1B3B6 61.41%, #EB9B9F 100%);
    border-radius: 39px;
    flex-wrap: wrap;
    padding: 30px 0px;
    max-width: 450px;
    margin: 22px 0px 0px 0px;
    /* margin-bottom: 30px; */
}

.logodiiv {
    margin-left: 180px;
    margin-bottom: -47px;
    margin-top: -50px;
}
.logodiiv2 {
    margin-left: 180px;
    padding: 0px 0px 20px 0px;
    margin-bottom: -47px;
    margin-top: -50px;
}
.logodiiv2 img{
    width: 60px;
}
.logodiiv img {
    width: 60px;
}

.userspic {
    margin-left: 180px;
    margin-bottom: -47px;
    margin-top: -60px;
    /* margin-bottom: -20px; */
}

.userspic img {
    width: 100%;
    margin-bottom: 37px;
}
.userspic33 {
    margin-left: 190px;
    margin-bottom: -47px;
    margin-top: -60px;
    /* margin-bottom: -20px; */
}

.userspic33 img {
    width: 100%;
    margin-bottom: 37px;
}
.logoimglatest{
    width: 70%;
}
@media only screen and (max-width: 1350px) {

    .singin {
        padding: 50px;
    }

    .number {
        max-width: 500px;
        padding: 60px;
    }

    .verifyno {
        padding: 35px;
    }

    .pic {
        width: 100%;
    }

    .pic img {
        width: 100%;
    }

    .logo {
        width: 100%;
        /* margin: 0px; */
    }

    /* .logoimg {
        width: 200px;
    } */

    .logoimglatest{
        width: 90%;
    }
    
    

    .verifysmltxt {
        padding: 11px 35px 0px;
    }

    .singbtn {
        width: 75%;
        margin-top: 20px;
    }

    .userinput {
        border-radius: 12px;
        width: 77% !important;
        margin: 20px 0px 0px 0px !important;
        border: 1px solid #C4C4C4 !important;
        font-family: 'Poppins', sans-serif !important;
    }

    .otpinput input {
        margin: 0px 5px !important;
        width: 69px !important;
        height: 69px !important;
    }

    /* .layout {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
    } */

}


@media only screen and (max-width:1000px) {
    .cypass{
        padding: 30px;
    }
}

@media only screen and (max-width: 950px) {
    .logodiiv {
        margin: 0;
    }

    /* .singin3{
        margin: auto;
        padding: 50px 10px;
    } */
    .userspic {
        margin: 0;
    }

    .Applistyy {
        display: flex !important;
        justify-content: center !important;
    }
.cypass{
    margin: 0;
    padding: 30px;
}

    .hddhhdhdd {
        display: flex !important;
        justify-content: center !important;
    }

    .singin3 {
        margin: 0;
    }

    .singin {
        margin: auto;
        padding: 50px 10px;
    }

    .number {
        max-width: 500px;
        padding: 50px 60px;
    }

    .verifyno {
        max-width: 500px;
        padding: 50px 40px;
    }

   

    .pic {
        width: 100%;
        margin: 50px 0px;
    }

    .pic img {
        width: 100%;
    }

    .logo {
        width: 100%;
    }

    .logoimg {
        width: 200px;
    }

    .singbtn {
        width: 75%;
        margin-top: 20px;
    }

    .verifysmltxt {
        padding: 11px 35px 0px;
    }

    .otpinput input {
        margin: 0px 5px !important;
        width: 60px !important;
        height: 60px !important;
    }

    .uplineimg {
        border-radius: 0px 20px;
    }

    .btmline {
        border-radius: 0px 10px;
        margin-top: -20px;
        height: 90px;
        position: relative;
    }

    .imgloglistkk2 {
        position: relative;
        padding-top: 100px;
        margin-top: 10px;

    }
    .main {
        min-height: 100vh;
        background-size: cover;
        background-position: center;
        padding: 120px 0;
    }
}

@media only screen and (max-width: 820px) {
   

    .pic {
        padding: 0;
    }

    .singin {
        margin-top: 50px;
        margin: auto;
    }
    
}

@media only screen and (max-width: 600px) {
    .singin2{
        margin: 20px;
    }
    .logodiiv {
        margin: 0;
    }

    .userspic {
        margin: 0;
    }

    .pic {
        padding: 0;
    }

    .singin3 {
        margin: 20px;
        padding: 20px;
    }

    

    .singin {
        margin: 0 auto !important;
        margin-top: 0px !important;
    }

    .singin {
        padding: 50px 10px;
    }

    .number {
        max-width: 500px;
        padding: 60px;
    }

    .verifyno {
        padding: 35px;
    }

   

    .pic {
        width: 100%;
    }

    .pic img {
        width: 100%;
    }

    .logo {
        width: 100%;
    }

    .logoimg {
        width: 200px;
    }

    .singbtn {
        margin-top: 20px;
        width: 75%;
    }

    .verifysmltxt {
        padding: 11px 50px 0px;
    }

    .otpinput input {
        margin: 0px 3px !important;
        width: 60px !important;
        height: 60px !important;
    }

    .uplineimg {
        border-radius: 0px 10px;
    }

    .btmline {
        /* transform: rotate(90deg); */
        padding: 20px 0px 0px 0px;
        border-radius: 0px 10px;
        margin-top: -20px;
        height: 90px;
        position: relative;
    }

    /* .singin3{
        margin: auto;
    } */
    .imgloglistkk2 {
        position: relative;
        padding-top: 100px;
        margin-top: 10px;

    }
    .main{
        min-height: 100vh;
        background-size: cover;
        background-position: 84%;
        padding: 60px 0 120px;
    }
}